<template>
  <div>
    <div
      class="pb-2"
      v-if="
        !(
          (institution && institution.internal_use_id == 'duoc_uc') ||
          $debug_change_duoc
        )
      "
    >
      <b-form-select
        v-model="selected_section_view"
        value-field="value"
        text-field="name"
        @change="changeSectionView()"
        class="mt-4 mb-1"
      >
        <option :value="null" disabled>
          -- Seleccione el Filtro a Utilizar --
        </option>
        <option
          v-for="section_view in sections_view"
          :key="section_view.value"
          :value="section_view.value"
        >
          {{ section_view.name }}
        </option>
      </b-form-select>
    </div>
    <div class="pb-2" v-if="egressProfileList.length > 1">
      <p class="pb-4">
        {{ $getVisibleNames("mesh.egressprofile", true, "Perfiles De Egreso") }}
      </p>
      <b-form-select
        v-model="selected_egress_profile_id"
        :options="egressProfileList"
        value-field="id"
        text-field="name"
        @change="
          () => {
            selected_period_id = null;
          }
        "
        :placeholder="
          $getVisibleNames('mesh.egressprofile', true, 'Perfiles De Egreso')
        "
        class="mt-4 mb-1"
      ></b-form-select>
    </div>
    <div v-if="selected_section_view == 1" class="select-container">
      <div class="upper-selector mr-2">
        <p>Tipo de {{ $getVisibleNames("mesh.career", false, "Programa") }}</p>
        <b-form-select
          v-model="profile_type_id"
          value-field="id"
          text-field="name"
          dense
          attach
          hide-details
          @change="
            () => {
              selected_career_id = null;
              selected_egress_profile_id = null;
              selected_period_id = null;
            }
          "
          class="mt-4 mb-1"
        >
          <option :value="null" selected>
            -- Seleccione Tipo de
            {{ $getVisibleNames("mesh.career", false, "Programa") }} --
          </option>
          <option v-for="type in profileTypes" :key="type.id" :value="type.id">
            {{ type.name }}
          </option>
        </b-form-select>
      </div>
      <div class="upper-selector">
        <p>
          {{ $getVisibleNames("mesh.career", false, "Programa") }}
        </p>
        <b-form-select
          v-model="selected_career_id"
          :options="careerList"
          value-field="id"
          text-field="name"
          dense
          attach
          hide-details
          @change="
            (value) => {
              selected_period_id = null;
              if (value == null) selected_egress_profile_id = null;
            }
          "
          class="mt-4 mb-1"
          ><template #first>
            <b-form-select-option :value="null"
              >-- Seleccione
              {{ $getVisibleNames("mesh.career", false, "Programa") }} para
              filtrar
              {{ $getVisibleNames("teaching.section", true, "Secciones") }}
              --</b-form-select-option
            >
          </template></b-form-select
        >
      </div>
      <div class="upper-selector">
        <p v-if="egressProfile" class="ml-2">
          {{ $getVisibleNames("mesh.campus", false, "Sede") }}
        </p>
        <b-form-select
          v-if="egressProfile"
          v-model="selected_campus_id"
          :options="filteredCampuses"
          value-field="id"
          text-field="name"
          attach
          clearable
          dense
          hide-details
          class="mt-4 mb-1 ml-2"
        >
          <template #first>
            <b-form-select-option :value="null"
              >-- Seleccione una
              {{ $getVisibleNames("mesh.campus", false, "Sede") }}
              --</b-form-select-option
            >
          </template>
        </b-form-select>
      </div>
    </div>
    <div v-else-if="selected_section_view == 2">
      <div class="select-container">
        <div class="upper-selector">
          <p class="ml-2">
            <strong>
              {{ $getVisibleNames("teaching.matter", false, "Asignatura") }}
            </strong>
          </p>
          <v-select
            class="mb-1 ml-2"
            style="margin-top: 2.5em"
            v-model="selected_matter_id"
            :options="sortMatters"
            :reduce="(sortMatters) => sortMatters.id"
            :placeholder="`${String(
              '-- Seleccione una ' +
                $getVisibleNames(
                  'teaching.matter',
                  false,
                  'Asignatura'
                ).toLowerCase()
            )} --`"
            label="label"
            track-by="id"
            size="sm"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No se encontró resultados para <em>{{ search }}</em
                >.
              </template>
            </template>
            <template v-slot:option="option">
              {{ option.label }}
            </template>
          </v-select>
        </div>
        <div class="upper-selector">
          <p class="ml-2">
            {{ $getVisibleNames("mesh.campus", false, "Sede") }}
          </p>
          <b-form-select
            v-model="selected_campus_id"
            :options="sortCampus"
            value-field="id"
            text-field="name"
            attach
            clearable
            dense
            hide-details
            class="mt-4 mb-1 ml-2"
          >
            <template #first>
              <b-form-select-option :value="null"
                >-- Seleccione una
                {{ $getVisibleNames("mesh.campus", false, "Sede") }}
                --</b-form-select-option
              >
            </template>
          </b-form-select>
        </div>
        <!-- <div class="upper-selector ml-2">
          <p class="ml-2">Nivel</p>
          <b-form-select
            v-model="selected_level"
            :options="levels"
            attach
            clearable
            dense
            hide-details
            label="Nivel"
            class="mt-4 mb-1 ml-2"
            ><template #first>
              <b-form-select-option :value="null"
                >-- Seleccione un Nivel --</b-form-select-option
              >
            </template></b-form-select
          >
        </div> -->
        <div class="upper-selector pl-2">
          <p class="ml-2">
            {{ $getVisibleNames("mesh.periodtype", false, "Régimen") }}
          </p>
          <b-form-select
            v-model="selected_period_type_id"
            :options="regimes"
            value-field="id"
            text-field="name"
            attach
            clearable
            dense
            hide-details
            @change="
              (value) => {
                selected_period_id = null;
              }
            "
            :label="$getVisibleNames('mesh.periodtype', false, 'Régimen')"
            class="mt-4 ml-2"
            ><template #first>
              <b-form-select-option :value="null"
                >-- Seleccione una
                {{ $getVisibleNames("mesh.periodtype", false, "Régimen") }}
                --</b-form-select-option
              >
            </template></b-form-select
          >
        </div>
      </div>
      <div class="select-container mt-3">
        <div class="lower-selector mr-2">
          <p class="ml-3">
            {{ $getVisibleNames("mesh.period", false, "Período") }}
          </p>
          <b-form-select
            v-model="selected_period_id"
            :options="periodList"
            value-field="id"
            text-field="name"
            attach
            clearable
            dense
            hide-details
            :label="$getVisibleNames('mesh.period', false, 'Período')"
            class="mt-3 mb-1 ml-2"
            ><template #first>
              <b-form-select-option :value="null"
                >-- Seleccione una
                {{ $getVisibleNames("mesh.period", false, "Período") }}
                --</b-form-select-option
              >
            </template></b-form-select
          >
        </div>
        <div class="lower-selector">
          <p class="ml-2">
            {{ $getVisibleNames("mesh.shift", false, "Jornada") }}
          </p>
          <b-form-select
            v-model="selected_shift_id"
            :options="shifts"
            value-field="id"
            text-field="name"
            attach
            clearable
            dense
            hide-details
            :label="$getVisibleNames('mesh.shift', false, 'Jornada')"
            class="mt-3 mb-1 ml-2"
            ><template #first>
              <b-form-select-option :value="null"
                >-- Seleccione una
                {{ $getVisibleNames("mesh.shift", false, "Jornada") }}
                --</b-form-select-option
              >
            </template></b-form-select
          >
        </div>
      </div>
    </div>
    <div v-if="egressProfile" class="select-container mt-3">
      <div class="lower-selector">
        <p v-if="egressProfile">Nivel</p>
        <b-form-select
          v-model="selected_level"
          :options="levels"
          attach
          clearable
          dense
          hide-details
          label="Nivel"
          class="mt-3 mb-1 mr-4"
          ><template #first>
            <b-form-select-option :value="null"
              >-- Seleccione un Nivel --</b-form-select-option
            >
          </template></b-form-select
        >
      </div>
      <div class="lower-selector">
        <p v-if="egressProfile" class="ml-2">
          {{ $getVisibleNames("mesh.period", false, "Período") }}
        </p>
        <b-form-select
          v-model="selected_period_id"
          :options="periodList"
          value-field="id"
          text-field="label"
          attach
          clearable
          dense
          hide-details
          :label="$getVisibleNames('mesh.period', false, 'Período')"
          class="mt-3 mb-1 mr-2 ml-2"
          ><template #first>
            <b-form-select-option :value="null"
              >-- Seleccione un
              {{ $getVisibleNames("mesh.period", false, "Período") }}
              --</b-form-select-option
            >
          </template></b-form-select
        >
      </div>
      <div class="lower-selector">
        <p v-if="egressProfile" class="ml-4">
          {{ $getVisibleNames("mesh.shift", false, "Jornada") }}
        </p>
        <b-form-select
          v-model="selected_shift_id"
          :options="shifts"
          value-field="id"
          text-field="name"
          attach
          clearable
          dense
          hide-details
          :label="$getVisibleNames('mesh.shift', false, 'Jornada')"
          class="mt-3 mb-1 ml-4"
          ><template #first>
            <b-form-select-option :value="null"
              >-- Seleccione una
              {{ $getVisibleNames("mesh.shift", false, "Jornada") }}
              --</b-form-select-option
            >
          </template></b-form-select
        >
      </div>
    </div>
    <b-button
      v-can="'teaching.add_section'"
      v-if="
        (allows_crud &&
          this.selected_egress_profile_id != null &&
          this.matterList.length > 0) ||
        allows_crud
      "
      size="sm"
      variant="primary"
      @click="createSection"
      class="mb-3 mt-4"
      >Agregar
      {{ $getVisibleNames("teaching.section", false, "Sección") }}</b-button
    >
    <div class="current-sections">
      <b-table
        v-if="filteredSections.length"
        :items="filteredSections"
        :fields="fields"
        responsive="sm"
        outlined
        small
        class="custom"
        head-variant="light"
        :caption-top="true"
        bordered
        hover
      >
        <template v-slot:cell(matter)="row">
          <span class="current-sections-item" v-if="selected_career_id">
            <b-link :id="`popover-button-variant-${row.item.id}`">
              {{ getMatterName(row.item.egress_profile_matter) }}
            </b-link>
            <b-popover
              :target="`popover-button-variant-${row.item.id}`"
              triggers="focus"
            >
              <template #title>Navegación:</template>
              <div>
                <template>
                  <div class="profiles-container">
                    <b-button
                      variant="none"
                      :to="{
                        name: 'MatterProgramView',
                        params: {
                          matter_id: row.item.egress_profile_matter,
                        },
                      }"
                      class="profiles mb-1 text-white"
                    >
                      Ir al
                      {{
                        $getVisibleNames(
                          "manual.matter_program",
                          false,
                          "Programa de la Asignatura"
                        )
                      }}.
                    </b-button>
                    <!-- TODO: Se comento mientras se trabaja en las Evaluaciones. -->
                    <!-- <b-button
                      variant="none"
                      :to="{
                        name: 'PlanificationView',
                        params: {
                          matter_id: row.item.egress_profile_matter,
                        },
                      }"
                      class="profiles mb-1 text-white"
                    >
                      Ir a la planificación.
                    </b-button> -->
                  </div>
                </template>
              </div>
            </b-popover>
          </span>
          <span v-else class="current-sections-item">
            <b-link :id="`popover-button-variant-matter-${row.item.id}`">
              {{
                (matters.find((x) => x.id == row.item.matter) || {}).name || ""
              }}
            </b-link>
            <b-popover
              :target="`popover-button-variant-matter-${row.item.id}`"
              triggers="focus"
            >
              <template #title>Navegación:</template>
              <div>
                <template>
                  <div class="profiles-container">
                    <b-button
                      variant="none"
                      :to="{
                        name: 'OldMatterProgramView',
                        params: {
                          matter_id: row.item.matter,
                        },
                      }"
                      class="profiles mb-1 text-white"
                    >
                      Ir al
                      {{
                        $getVisibleNames(
                          "manual.matter_program",
                          false,
                          "Programa de la Asignatura"
                        )
                      }}.
                    </b-button>
                  </div>
                </template>
              </div>
            </b-popover>
          </span>
        </template>
        <template v-slot:cell(name)="row">
          <span class="current-sections-item">
            <b-link
              :title="
                'Ver ' + $getVisibleNames('teaching.section', false, 'Sección')
              "
              @click="launchModalUpdateSection(row.item)"
              >{{ row.item.name }}</b-link
            >
          </span>
        </template>
        <template v-slot:cell(shift)="row">
          <b-badge
            class="pill-badge"
            :variant="row.item.shift % 2 == 0 ? 'secondary' : 'info'"
            pill
          >
            {{ shiftName(row.item.shift) }}
          </b-badge>
        </template>
        <template v-slot:cell(period)="row">
          {{ periodName(row.item.period) }}
        </template>
        <template v-slot:cell(professors)="row">
          <template
            v-for="professor in users.filter((x) =>
              row.item.professors.includes(x.id)
            )"
          >
            <div :key="row.item.id + '-' + professor.id">
              <b-badge v-b-tooltip.v-secondary.noninteractive="professor.email">
                {{ professor.first_name }}
                {{ professor.last_name }}
              </b-badge>
            </div>
          </template>
        </template>
        <template v-slot:cell(action)="row">
          <div v-if="allows_crud">
            <button-edit
              v-can="'teaching.change_section'"
              @click="launchModalUpdateSection(row.item)"
            >
            </button-edit>
            <button-delete
              v-can="'teaching.delete_section'"
              @click="askForDelete(row.item)"
            ></button-delete>
          </div>
          <div v-else>
            <b-button
              size="sm"
              variant="none"
              @click="launchModalUpdateSection(row.item)"
              ><b-icon icon="eye" scale="1"></b-icon
            ></b-button>
          </div>
        </template>
      </b-table>
    </div>
    <!-- Modals -->
    <b-modal
      :title="'Crear ' + $getVisibleNames('teaching.section', false, 'Sección')"
      hide-footer
      size="xl"
      id="modal-create-section"
    >
      <SectionForm
        :egress_profile_id="selected_egress_profile_id"
        :allows_crud="allows_crud"
        :period_id="selected_period_id"
        :shift_id="selected_shift_id"
        :campus_id="selected_campus_id"
        :selected_level="selected_level"
        :period_type="periodType"
        :selected_matter_id="selected_matter_id"
        @created="slotCreatedSection"
        @updated="slotCreatedSection"
      ></SectionForm>
    </b-modal>
    <b-modal
      :title="
        'Modificar ' + $getVisibleNames('teaching.section', false, 'Sección')
      "
      hide-footer
      size="xl"
      id="modal-update-section"
    >
      <SectionForm
        :Section="selected_section"
        :egress_profile_id="selected_egress_profile_id"
        :period_type="periodType"
        :allows_crud="allows_crud"
        @updated="slotUpdatedSection"
        @deleted="slotUpdatedSection"
      ></SectionForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters, mapMutations } from "vuex";
import { toast } from "@/utils/utils";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "SectionList",
  components: {
    vSelect,
    SectionForm: () => import("./SectionForm"),
  },
  props: {
    // career_id: {
    //   type: Number,
    // },
    // level: {
    //   type: Number,
    // },
    // period_id: {
    //   type: Number,
    // },
    // campus_id: {
    //   type: Number,
    // },
    // shift_id: {
    //   type: Number,
    // },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_career_id: Number(this.$route.params.career_id)
        ? Number(this.$route.params.career_id)
        : null,
      selected_level: Number(this.$route.params.plan_level)
        ? Number(this.$route.params.plan_level)
        : null,
      selected_egress_profile_id: null,
      selected_period_id: null,
      profile_type_id: null,
      horizontal_layout: false,
      sections: [],
      levels: [],
      selected_shift_id: null,
      selected_campus_id: null,
      selected_matter_id: null,
      selected_period_type_id: null,
      // selected_matter_id: Number(this.$route.params.matter_id)
      //   ? Number(this.$route.params.matter_id)
      //   : null,
      selected_section: null,
      selected_section_view: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      users: names.USERS,
      careers: names.CAREERS,
      campuses: names.CAMPUSES,
      egressProfiles: names.EGRESS_PROFILES,
      cycles: names.CYCLES,
      faculties: names.FACULTIES,
      profileTypes: names.PROFILE_TYPES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
      periods: names.PERIODS,
      regimes: names.REGIMES,
      shifts: names.SHIFTS,
      section_list: names.SECTIONS,
    }),
    careerList() {
      if (this.profile_type_id == null)
        return this.careers.filter((x) => x.egress_profiles.length > 0);
      else
        return this.careers.filter(
          (x) =>
            x.egress_profiles.length > 0 &&
            x.egress_profile_type == this.profile_type_id
        );
    },
    career() {
      return this.careers.find((x) => x.id == this.selected_career_id);
    },
    egressProfile() {
      if (!this.selected_egress_profile_id) return null;
      return this.egressProfiles.find(
        (x) => x.id == this.selected_egress_profile_id
      );
    },
    periodType() {
      if (this.egressProfile && this.selected_section_view == 1)
        return this.egressProfile.regime;
      else return this.selected_period_type_id;
    },
    sortMatters() {
      return this.matters
        .map((x) => ({ ...x, label: x.code + " - " + x.name }))
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });
    },
    sections_view() {
      return [
        {
          value: 1,
          name:
            "Filtrar por " +
            this.$getVisibleNames(
              "mesh.egressprofile",
              false,
              "Perfil De Egreso"
            ),
        },
        {
          value: 2,
          name:
            "Filtrar por " +
            this.$getVisibleNames("teaching.matter", false, "Asignatura"),
        },
      ];
    },
    fields() {
      return [
        {
          key: "matter",
          label: this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          ),
          tdClass: "text-left",
          sortable: true,
        },
        {
          key: this.selected_section_view == 1 ? "plan_level" : "",
          label: this.selected_section_view == 1 ? "Nivel" : "",
          sortable: true,
          tdClass: "text-center",
        },
        { key: "name", label: "Nombre", sortable: true, tdClass: "text-left" },
        {
          key: "shift",
          label: String(this.$getVisibleNames("mesh.shift", false, "Jornada")),
          tdClass: "text-center",
          sortable: true,
        },
        {
          key: "period",
          label: String(this.$getVisibleNames("mesh.period", false, "Período")),
          tdClass: "text-left",
          sortable: false,
        },
        {
          key: "professors",
          label: "Docentes",
          sortable: true,
          tdClass: "text-left",
        },
        { key: "action", label: "" },
        // { key: "campus", label: String(this.$getVisibleNames('mesh.campus', false, 'Sede')), sortable: true }, TODO: Mostrar la Sede.
        //{ key: "show_details", label: "Color" },
      ];
    },
    egressProfileList() {
      let list = [];
      if (this.career) {
        this.career.egress_profiles.forEach((element) => {
          const instance = this.egressProfiles.find((x) => x.id == element);
          if (instance) list.push(instance);
          else this.$store.dispatch(names.FETCH_EGRESS_PROFILE, element);
        });
      }
      return list;
    },
    filteredCampuses() {
      if (!this.egressProfile) return this.campuses;
      let list = [];
      this.egressProfile.campuses.forEach((campus_id) => {
        const instance = this.sortCampus.find((x) => x.id == campus_id);
        if (instance) list.push(instance);
      });
      return list.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
    sortCampus() {
      return [...this.campuses].sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
    matterList() {
      let list = [];
      if (!this.selected_egress_profile_id) return list;
      list = this.egress_profiles_matters.filter(
        (x) => x.egress_profile == this.selected_egress_profile_id
      );
      if (this.selected_level)
        list = list.filter((x) => x.plan_level == this.selected_level);
      return list;
    },
    // matterWithoutSections() {
    //   let list = this.matterList.filter((x) => x.sections.length == 0);
    //   let tmp_list = this.matterList.filter((x) => x.sections.length > 0);
    //   let flag = false;
    //   tmp_list.forEach((matter) => {
    //     matter.sections.forEach((section_id) => {
    //       if (this.filteredSections.find((x) => x.id == section_id))
    //         flag = true;
    //     });
    //     if (!flag) list.push(matter);
    //   });
    //   return list;
    // },
    period() {
      if (!this.selected_period_id) return null;
      return this.periods.find((x) => x.id == this.selected_period_id);
    },

    periodList() {
      let periods_list = this.periods.map((period) => ({
        ...period,
        label: `${period.name} De: ${period.start_date} al ${period.end_date}`,
      }));
      if (!this.periodType) return periods_list;
      return periods_list.filter((x) => x.period_type == this.periodType);
    },
    filteredSections() {
      let list = this.sections.map((x) => {
        return {
          ...x,
          plan_level: this.getMatterPlanLevel(x.egress_profile_matter),
        };
      });
      if (this.selected_section_view == 1) {
        if (this.selected_career_id == null) return [];
        if (this.selected_shift_id)
          list = list.filter((x) => x.shift == this.selected_shift_id);
        if (this.selected_campus_id)
          list = list.filter((x) => x.campus == this.selected_campus_id);
        if (this.selected_level)
          list = list.filter((x) => x.plan_level == this.selected_level);
        if (this.selected_period_id)
          list = list.filter((x) => x.period == this.selected_period_id);
        return list;
      } else {
        list.filter((x) => x.egress_profile_matter == null);
        if (this.selected_matter_id)
          list = list.filter((x) => x.matter == this.selected_matter_id);
        if (this.selected_shift_id)
          list = list.filter((x) => x.shift == this.selected_shift_id);
        if (this.selected_campus_id)
          list = list.filter((x) => x.campus == this.selected_campus_id);
        if (this.selected_level)
          list = list.filter((x) => x.plan_level == this.selected_level);
        if (this.selected_period_id)
          list = list.filter((x) => x.period == this.selected_period_id);
        return list;
      }
    },
    selectedSections() {
      return this.filteredSections.filter((x) => x.show_in_calendar);
    },
  },
  methods: {
    ...mapMutations(["DeleteUserSectionProfessor"]),
    changeSectionView() {
      this.sections = [];
      this.selected_matter_id = null;
      this.selected_career_id = null;
      this.selected_egress_profile_id = null;
      this.selected_period_type_id = null;
      this.selected_period_id = null;
      this.selected_level = null;
      this.selected_campus_id = null;
      this.selected_shift_id = null;
    },
    emitSections(section) {
      const index = this.sections.findIndex((x) => x.id == section.id);
      if (index != -1) {
        const flag = this.sections[index].show_in_calendar;
        this.sections[index].show_in_calendar = !flag;
      }
      //this.emitSelectedSections();
    },
    shiftName(shift_id) {
      return this.shifts.find((x) => x.id == shift_id).name;
    },
    periodName(id) {
      return this.periods.find((x) => x.id == id).name;
    },
    selectAllSections(flag) {
      this.sections.forEach((element) => {
        element.show_in_calendar = flag;
        let checkbox = this.$refs[`checkbox-${element.id}`];
        if (checkbox) {
          try {
            checkbox.checked = flag;
          } catch {
            () => {};
          }
        }
      });

      //this.emitSelectedSections();
    },
    getMatterName(egress_profile_matter_id) {
      const egress_profile_matter = this.egress_profiles_matters.find(
        (x) => x.id == egress_profile_matter_id
      );
      if (egress_profile_matter) {
        const matter = this.matters.find(
          (x) => x.id == egress_profile_matter.matter
        );
        return matter ? matter.name : "";
      } else return "";
    },
    getMatterPlanLevel(egress_profile_matter_id) {
      if (!egress_profile_matter_id) return null;
      const egress_profile_matter = this.egress_profiles_matters.find(
        (x) => x.id == egress_profile_matter_id
      );
      if (egress_profile_matter) {
        return egress_profile_matter ? egress_profile_matter.plan_level : null;
      } else return null;
    },
    updateColor(section) {
      this.$store
        .dispatch(names.PATCH_SECTION, {
          section_id: section.id,
          item: { color: section.color },
        })
        .then(() => {
          //this.emitSelectedSections();
        });
    },
    //// emitSelectedSections() {
    //   let sections_id = [];
    //   this.filteredSections.forEach((element) => {
    //     if (element.show_in_calendar) sections_id.push(element.id);
    //   });
    //   this.$emit("update_section_list", sections_id);
    // },
    createSection() {
      if (this.selected_egress_profile_id == null && this.sections_view == 1) {
        this.$swal({
          title: `Debe seleccionar ${this.$getVisibleNames(
            "mesh.career",
            false,
            "Programa"
          )} antes de Agregar ${this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          )}.`,
          type: "warning",
        });
      } else {
        if (this.matterList.length == 0 && this.sections_view == 1) {
          this.$swal({
            title: `Debe añadir al menos una ${this.$getVisibleNames(
              "mesh.egressprofilematter",
              false,
              "Asignatura"
            )} en ${this.$getVisibleNames(
              "mesh.career",
              false,
              "Programa"
            )} para crear ${this.$getVisibleNames(
              "teaching.section",
              false,
              "Sección"
            )}.`,
            type: "warning",
          });
        } else {
          this.$bvModal.show("modal-create-section");
        }
      }
    },
    // createSectionForMatter(matter) {
    //   this.selected_matter_id = matter.id;
    //   this.$bvModal.show("modal-create-section-for-matter");
    // },
    slotCreatedSection() {
      this.$bvModal.hide("modal-create-section-for-matter");
      this.$bvModal.hide("modal-create-section");
      this.sections = this.section_list;
      // this.loadSections();
      // this.selected_matter_id = null;
    },
    launchModalUpdateSection(section) {
      this.selected_section = section;
      this.$bvModal.show("modal-update-section");
    },
    slotUpdatedSection() {
      this.sections = this.section_list;
      // this.loadSections();
      this.$bvModal.hide("modal-update-section");
    },
    loadSections() {
      if (!this.selected_egress_profile_id) return;
      this.$store
        .dispatch(names.FETCH_EGRESS_PROFILE, this.selected_egress_profile_id)
        .then((ep) => {
          this.levels = [];
          for (let level = 1; level <= ep.semester_amount; level++) {
            this.levels.push(level);
          }
        });
      this.sections = [];
      // let counter = 0;
      this.$store.dispatch(names.FETCH_MATTERS, {
        egress_profile_id: this.selected_egress_profile_id,
      });
      this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
        egress_profile_id: this.selected_egress_profile_id,
      });
      this.$store
        .dispatch(names.FETCH_SECTIONS, {
          egress_profile_id: this.selected_egress_profile_id,
        })
        .then((response) => {
          this.sections = [...response];
        });
    },
    askForDelete(section) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Sección?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_SECTION, section.id).then(() => {
            let payload = { user_id: this.user.id, section: section };
            this.DeleteUserSectionProfessor(payload);
            // this.loadSections();
            this.sections = this.section_list;
            toast(
              this.$getVisibleNames("teaching.section", false, "Sección") +
                " eliminada."
            );
          });
        }
      });
    },
  },
  created() {
    if (
      (this.institution && this.institution.internal_use_id == "duoc_uc") ||
      this.$debug_change_duoc
    ) {
      this.selected_section_view = 1;
    }
    // this.selected_career_id = this.career_id;
    // this.selected_level = this.level;
    // this.selected_period_id = this.period_id;
    // this.selected_shift_id = this.shift_id;
    // this.selected_campus_id = this.campus_id;
    if (this.career && this.career.egress_profiles.length > 0)
      this.selected_egress_profile_id = this.career.egress_profiles[0];
  },
  watch: {
    career() {
      if (this.career && this.career.egress_profiles.length > 0)
        this.selected_egress_profile_id = this.career.egress_profiles[0];
    },
    selected_egress_profile_id() {
      this.loadSections();
      //this.$emit("changed_egress_profile", this.selected_egress_profile_id);
    },
    selected_shift_id() {
      //this.emitSelectedSections();
    },
    selected_campus_id() {
      //this.emitSelectedSections();
    },
    selected_level() {
      //this.emitSelectedSections();
    },
    selected_matter_id(value) {
      if (this.selected_section_view == 2 && value != null)
        this.$store
          .dispatch(names.FETCH_SECTIONS, { matter_id: value })
          .then((response) => {
            this.sections = [...response];
          });
    },
    selected_section_view(value) {
      if (value == 2) {
        this.levels = [];
        for (let i = 1; i <= this.institution.max_semester_amount; i++) {
          this.levels.push(i);
        }
        this.$store.dispatch(names.FETCH_MATTERS);
      }
    },
    matterList() {
      this.matterList.forEach((matter) => {
        matter.sections.forEach((section_id) => {
          this.$store.dispatch(names.FETCH_SECTION, section_id);
        });
      });
    },
    // filteredCampuses() {
    // if (this.filteredCampuses.length > 0 && !this.selected_campus_id) {
    //   this.selected_campus_id = this.filteredCampuses[0].id;
    // }
    // },
  },
};
</script>

<style scoped>
.card-body {
  padding: 0px;
}
.current-sections {
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.select-container {
  display: flex;
}
.pill-badge {
  font-size: 13px;
}
.current-sections-item:hover {
  background: rgb(208, 208, 208);
  border-radius: 8px;
}
p {
  float: left;
  margin-bottom: -2% !important;
}
.upper-selector {
  width: 50%;
}
.lower-selector {
  width: 33%;
}
.custom {
  background: rgb(226, 226, 226) !important;
}
.profiles-container {
  width: 100%;
}
.profiles {
  background-color: var(--secondary-color);
  border-radius: 4px;
  padding: 0 2em;
  width: 100%;
  font-size: 11pt;
  text-align: center;
}
</style>